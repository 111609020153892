/* Navbar.js */

body {
  margin: 0;
  /* background-color: #8e94a5; */
  background-color: #303443;
}

.navbar {
  transition: 300ms;
  width: 100%;
  height: 20px;
  opacity: 0;
  background-color: #303443;
  display: flex;
  align-items: center;
  top: 0;
}

.navbar div {
  width: 100%;
  display: flex;
  gap: 10%;
  justify-content: center;
}

.navbar a {
  margin-left: 50px;
  text-decoration: none;
  font-size: 23px;
  color: #7371ff;
  font-weight: 700;
}

.navbar:hover {
  opacity: 100;
  transition: 300ms;
  height: 50px;
  margin-bottom: 10px;
}


/* GameWindow.js */

.chessBoard {
  display: grid;
  place-content: center;
  grid-template-columns: repeat(9, 9vmin);
  grid-template-rows: repeat(9, 9vmin);
}

.square {
  display: grid;
  place-content: center;
  border: 1px solid;
  user-select: none;
  background-color: #707b97;
}

.square:focus {
  background-color: rgb(241, 142, 142);
}

.light {
  background-color: #e8edf9;
}

.dark {
  background-color: #b7c0d8;
}

.chessPiece {
  width: 97%;
  height: 97%;
}

.players {
  display: flex;
  gap: 12%;
  justify-content: center;
  padding-top: 20px;
}

.players > button {
  display: flex;
  gap: 30%;
}

.players > button > span {
  font-size: large;
  font-weight: 600;
}


/* End Game CSS */

.outer {
  height: 80vh;
  display: grid;
  place-items: center center;
  margin-top: 10vh;
}

.endText1, .endText2 {
  opacity: 0;
}

.endText, .endText2{
  animation: typing 2s steps(42), blink .5s step-end infinite alternate, removeBorder1 2s forwards 5s;
  /* animation-fill-mode: forwards; */
  white-space: pre;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  color: #E9DCC9;
  font-size: 2em;
}

.endText1 {
  opacity: 0;
  animation: typing 2s steps(42), blink .5s step-end infinite alternate, removeBorder2 2s forwards 5s;
  /* animation-fill-mode: forwards; */
  white-space: pre;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  color: #E9DCC9;
  font-size: 2em;
}

.endText2 {
  animation-delay: 2s;
}


@keyframes removeBorder1 {
  0% {
    border-right: 3px solid;
  }
  99% {
    border-right: 3px solid;
  }
  100% {
    border-right: 0;
  }
}

@keyframes removeBorder2 {
  0% {
    border-right: 3px solid;
  }
  99% {
    border-right: 3px solid;
  }
  100% {
    border-right: 0;
  }
}

@keyframes typing {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
  from {
    width: 0
  }
}
    
@keyframes blink1 {
  50% {
    border-color: transparent
  }
}

@keyframes blink2 {
  50% {
    border-color: transparent
  }
}

.endText1::after, .endText2::after {
  opacity: 1;
}

/* End Game CSS ends */

/* CSS for button */

@keyframes stripe-slide {
  0% {
    background-position: 0% 0;
 }
  100% {
    background-position: 100% 0;
 }
}

.btn {
  width: 54vw;
  overflow: visible;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  font: inherit;
  line-height: normal;
  cursor: pointer;
  user-select: text;
  -moz-user-select: text;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  padding: 16px 36px 18px;
  background-color: #fff;
  color: #666;
  border: 2px solid #666;
  border-radius: 6px;
  margin-bottom: 16px;
  transition: all 0.5s ease;
}
.btn:-moz-focus-inner {
  padding: 0;
  border: 0;
}
.btn--stripe {
  overflow: hidden;
  position: relative;
}
.btn--stripe:after {
  content: '';
  display: block;
  height: 7px;
  width: 100%;
  background-image: repeating-linear-gradient(45deg, #666, #666 1px, transparent 2px, transparent 5px);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-top: 1px solid #666;
  position: absolute;
  left: 0;
  bottom: 0;
  background-size: 7px 7px;
}
.btn--stripe:hover {
  background-color: #666;
  color: #fff;
  border-color: #000;
}
.btn--stripe:hover:after {
  background-image: repeating-linear-gradient(45deg, #fff, #fff 1px, transparent 2px, transparent 5px);
  border-top: 1px solid #000;
  animation: stripe-slide 12s infinite linear forwards;
}
.btn--large {
  width: 90%;
}
.btn--radius {
  border-radius: 36px;
}


/* CSS for button ends */

/* CSS for piece selection */

.selection {
  position: absolute;
  display: grid;
  grid-template-rows: repeat(2, 20%);
  grid-template-columns: repeat(2, 30%);
  width: 100vw;
  height: 100vh;
  top: 0;
  place-content: center;
  left: 2vw;
  
  justify-content: center;
  align-items: center;
  background-color: #303443;
  opacity: 80%;
}

/* CSS for piece selection ends */

/* CSS for Footer starts */

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #E9DCC9;
  font-size: 1.1rem;
  padding-bottom: 10px;
}

.footer a {
  text-decoration: none;
  color: #E9DCC9;
  font-weight: lighter;
}

/* CSS for footer ends */